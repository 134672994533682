export const ApiConstant = Object.freeze({
  logIn: "auth/login",
  forgotPassword: "auth/forgot-password",
  resetPassword: "auth/reset-password",
  defaultPlace: "admin/default-place",
  addPrefecture: "admin/add-prefecture",
  prefectutreList: "admin/prefecture-List",
  addCity: "admin/add-city",
  cityandPrefectureList: "admin/prefecture-List",
  deleteCity: "admin/delete-city",
  createOrganization: "admin/create-organization",
  editOrganisation: "admin/edit-organization",
  organizationList: "admin/organization-list",
  inviteCreProvider: "admin/create-careProvider",
  careProviderList: "admin/careprovider-list",
  updateInvitation: "admin/edit-careProvider",
  suspendCreprovider: "admin/updateAccountStatus",
  resendInvitation: "admin/resend-invitation",
  profile: "admin/me",
  deleteFacility: "admin/delete-organization",
  careProviderDetail: "admin/careProviderDetails",
  careWorkerList: "admin/careWorkerList",
  updateCareWorkerAccountStatus: "admin/update-careWorker-accountStatus",
  getDocumentList: "admin/idproof-list",
  careWorkerDetail: "admin/careWorker",
  careBankDetails: "admin/bankDetail",
  IndividualIDpreview: "admin/idproof-preview",
  IDconfirmStatus: "admin/idproof-status",
  fulltimeJobList: "admin/fulltime-job-list",
  fullTimeJobDetail: "admin/fulltime-job-detail",
  partTimeJobList: "admin/part-time-job-list",
  getPartimeJobDetail: "admin/part-time-job-detail",
  getAttendenceList: "admin/attendence-List",
  fullTimeJobWithdraw: "admin/withdraw-job",
  editPartimeJob: "admin/edit-parttime-job",
  withdrawJob: "admin/withdraw-job",
  fulltimeApplicantList: "admin/fulltimeJob-applicant-List",
  fulltimeJobInterviewReport: "admin/fulltimeJob-interview-report",
  invoiceList: "admin/invoice-List",
  penaltyList: "admin/penalty-List",
  unauthorizedAbsentList: "admin/unauthorized-absenties-List",
  unAprovedAbsentList: "admin/unapproved-absentise",
  updateApplication: "admin/update-application",
  updateWithdrawHistory: "admin/update-withdrawal-history",
  changeAbsenteStatus: "admin/absenties-status",
  invoiceDetail: "admin/invoice-details",
  remittence: "admin/send-congratulatory-money",
  fulltimeJobSuspend: "admin/fulltimeJob-status",
  paymentHistory: "payment/payment-history",
  withdrawalRequestList: "admin/withdrawal-request-list",
  withdrawalRequestApproval: "payment/review-withdrawl-request",
  workerBankdetails: "admin/bankDetail",
  fileUploadRequest: "auth/profile-upload-request",
  createAnnouncement: "admin/announcement",
  announcementList: "admin/announcement-List",
  prefectureCityCheck: "admin/prefecture-city-check",
  deletePrefecture: "admin/delete-prefecture",
  subadminRegister: "admin/subadmin-register",
  subadminLoginAsCareProvider: "admin/subadmin-login-with-careProvider",
  inviteSubadmin: "admin/invite-subadmin",
  subAdminList: "admin/subadminlist",
  updateSubadmin: "admin/subadmin-update",
  suspendReactiveSubAdmin: "admin/subAdmin-accountStatus",
  resendSubadminInvitation: "admin/resend-subadmin-invitation",
  updateSettings: "admin/update-admin-settings",
  getSettings: "admin/get-admin-settings",
  jobReport: "report/monthly-job-report",
  userReport: "report/monthly-registered-user",
  retainPenaltyPoints: "admin/retain-penaltyPoints",
  notification: "messenger/notification",
  reviewDelete: "admin/review-delete",
  firebase_refresh_token_api:
    "https://securetoken.googleapis.com/v1/token?key={{fb_api_key}}",
  payment_confirmation: "admin/payment-confirmation",
  interviewReport: "report/interview-report",
  monthlyReport: "report/admin-dashboard-data",
  notificationViewed: "messenger/read-notification",
  amountTransferConfirmation: "admin/amount_transfer_confirmation",
  salesReport: "report/dashboard-salesandexp-graph",
  updateSuperAdmin: "admin/super-admin-update",
  invitation_list: "careProvider/invitation",
  worker_csv_download: "admin/careWorkerList-csv",
  organization: {
    updateMatchingSettings: (id: string) =>
      `admin/organizations/${id}/matching-settings`,
  },
});
