export const environment = {
  production: false,
  hmr: false,
  // apiUrl: 'http://localhost:3000/',
  apiUrl: "https://qa.api.monsuke.jp/",
  paymentApiUrl: "https://be.monsuke.jp/",
  firebaseConfig: {
    apiKey: "AIzaSyAWG_pm5a2B4EFdoIM5e1FvP9Qs_vWhyvk",
    projectId: "monsuke-qa",
    appId: "1:554427061020:web:bf7e692bdc16dd4d3920d9",
  },
  bugsnag: "96d222f578c5f3e0eb8e71c5f824a146",
  careProviderUrl: "https://qacp.monsuke.jp"
};
