export enum careTypes {}
export enum AccountStatus {
  ACTIVATE = "activate",
  DEACTIVATE = "deactivate",
}

export enum IDocumentType {
  IDPROOF = "id_proof",
  QUALIFICATION = "qualification",
}

export enum DateSortOrder {
  ASCENDING = "ascending",
  DESCENDING = "descending",
}

export enum IDstatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  UPDATED = "updated",
}

export enum DocumentType {
  IDDOCUMENT = "identity document",
  QUALIFICATION = "qualification_document",
}
export enum JobStatus {
  UNDER_RECRUITMENT = "under_recruitment",
  WAITING_FOR_APPROVAL = "waiting_for_approval",
  APPLICATION_CONFIRMED = "application_confirmed",
  APPLICATION_REJECTED = "application_rejected",
  RECRUITMENT_COMPLETED = "recruitment_completed",
  WAITING_FOR_REVIEW = "waiting_for_review",
}

export enum JobType {
  PART_TIME_JOB = "part_time_job",
  FULL_TIME_JOB = "full_time_job",
}
export enum fulltimeJobStatus {
  // UNDER_RECRUITMENT = 'under_recruitment',
  // WAITING_FOR_APPROVAL = "waiting_for_approval",
  APPROVED = "approved",
  REJECTED = "rejected",
}
export enum absentesStatus {
  UNAUTHORIZED_ABSENTE = "unauthorized_absente",
  UNAPPROVED_ABSENTES = "unapproved_absente",
}
export enum AbsentiesStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}
export enum WithdrawalType {
  REGULAR_TRANSFER = "regular_transfer",
  WITHDRAWAL_TRANSFER = "withdrawal_transfer",
}
export enum AnnouncementTab {
  PROVIDER = "provider",
  WORKER = "worker",
  PROVIDERWORKER = "provider-worker",
}
export enum AnnouncementViewer {
  USER = "USER",
  CARE_PROVIDER = "care_provider",
  ALL = "all",
}
export enum Places {
  PREFECTURE = "prefecture",
  CITY = "city",
}
export enum RewardApprovalStatus {
  REWARDCORRECTION = "reward_correction",
  COMPLETED = "completed",
  AUTOCONFIRMED = "autoconfirmed",
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}
export enum JobMatchingMode {
  APPROVAL_SYSTEM = "approval_system",
  AUTOMATIC_MATCHING_SYSTEM = "automatic_matching_system",
}


export enum RoleAccount{
  SUPER_ADMIN = "super_admin",
  SUB_ADMIN = "sub_admin",
  USER = "user",
  CARE_PROVIDER = "care_provider",
}

export enum WithdrawalRequestStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}