import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WithdrawalRequestAPIResponse, WithdrawalRequestResponse } from '@core/types/payment';
import { ApiConstant } from 'app/shared/api-constents';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseUrl = environment.apiUrl
 /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}

  getWithdrawList(query: HttpParams ): Observable<WithdrawalRequestResponse> {
    const url  = this.baseUrl + ApiConstant.withdrawalRequestList;
    return this._http.get<WithdrawalRequestAPIResponse>(url, { params: query }).pipe(map((res) => {
      return res.result;
    }));
  };


  approvalWithdrawalRequest(payload: {
    withdrawal_request_id: string;
    amount_transfer_confirmation: string;
  }): Observable<any> {
    const url = this.baseUrl + ApiConstant.withdrawalRequestApproval;
    return this._http.post(url, { payload });
  }
}
